<template>
<div>
	<keep-alive>
		<component :is="`Page_${activeId}`"></component>
	</keep-alive>
</div>
</template>

<script>
import { components } from './config'
export default {
	name: 'Solutions',
	components: { ...components },
	data() {
		return {
			activeId: 1
		}
	},
	watch: {
		$route(route) {
			this.setActiveId()
		}
	},
	created() {
		this.setActiveId()
	},
	methods: {
		setActiveId() {
			const { path, query } = this.$route
			if (!query.p || path !== '/solutions') {
				return
			}
			this.activeId = query.p
		}
	}
}
</script>

<style lang="less" scoped>
@import "./Solutions.less";
</style>
